<template>
  <VuePerfectScrollbar class="e-content__inner e-content__inner--wide">
    <div class="e-content__scroll">
      <div
        class="e-module e-module--limited e-module--left"
        :class="{ transparent, 'full-height': fullHeight }"
      >
        <Loading v-if="loading" />

        <header v-if="title && !loading" class="e-module__head">
          <h1>{{ title }}</h1>
        </header>
        <div v-if="!loading" class="e-module__body">
          <slot></slot>
        </div>
      </div>
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Loading from 'atoms/Loading/Loading'

export default {
  components: {
    Loading,
    VuePerfectScrollbar
  },
  props: {
    title: {
      type: String
    },
    loading: {
      type: Boolean
    },
    transparent: {
      type: Boolean
    },
    fullHeight: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.e-module.transparent {
  background-color: transparent;
  padding: 0;
}
.e-loading {
  display: block;
  margin: 0 auto;
}
.e-content__scroll {
  height: 100%;
}

.e-module {
  display: flex;
  flex-direction: column;
  height: auto;

  &.full-height {
    height: 100%;
  }

  &__body {
    flex-grow: 1;
  }
}
.e-overall .ps {
  overflow: auto !important;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  @include break(m) {
    overflow: hidden !important;
    @at-root .mobile {
      overflow: auto !important;
    }
  }
}
</style>
